import { ReactComponent as CheckIcon } from 'assets/images/check.svg'
import { ReactComponent as ChevronRight } from 'assets/images/chevron-right.svg'
import { PillButton } from 'components/Button'
import { useCallback, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { RoutePaths } from 'containers/Core/Routes'
import {
  Program,
  useOnboardingContext,
} from 'context/OnboardingContext/OnboardingProvider'
import {
  PROGRAMS,
  getProgramFeatures,
  getProgramName,
} from 'utils/program.utils'

import './styles.scss'
import { Amplitude } from 'utils/amplitude.utils'

interface CompleteProgramCardProps extends ProgramCardProps {
  program: Program
  subTitle?: string
  icon: string
  secondaryInfoTitle?: string
  onSelect?: (program: Program) => void
}

interface ProgramCardProps {
  isRecommended?: boolean
  isMobile?: boolean
}

const ProgramCard: React.FC<CompleteProgramCardProps> = ({
  program,
  subTitle,
  icon,
  isRecommended,
  onSelect,
}) => {
  const history = useHistory()
  const { setSelectedProgram } = useOnboardingContext()

  const features = useMemo(() => getProgramFeatures(program), [program])

  const chipText = useMemo(() => {
    if (isRecommended) {
      return 'Recommended For You'
    } else if (program === PROGRAMS.MNC) {
      return "What's included"
    }
  }, [program, isRecommended])

  const handleClick = useCallback(() => {
    if (onSelect) {
      onSelect(program)
    } else {
      setSelectedProgram(program)

      history.push(`${RoutePaths.PAYMENT_PLANS}${window.location.search}`)
    }

    Amplitude.userSelectsProduct(program)
  }, [window.location.search])

  return (
    <div
      className={`insurance-program-card mb-8 ${
        program !== PROGRAMS.INSURANCE
          ? 'd-flex flex-column justify-content-between'
          : ''
      }`}
    >
      <div>
        <div className="overflow-hidden position-relative pb-3">
          <div className="curve" />
          <div className="insurance-program-card-header pt-4 d-flex flex-column align-items-center">
            <div className="insurance-program-card-icon d-flex fs-4">
              <img
                className={`${
                  program !== PROGRAMS.INSURANCE ? 'non-insurance-icon' : ''
                }`}
                src={icon}
              />
            </div>
            <div>
              <h1 className="ff-inter-medium header text-center">
                {getProgramName(program)}
              </h1>
              {subTitle && (
                <p className="ff-inter-medium text-center sub-header fs-3">
                  {subTitle}
                </p>
              )}
            </div>
            {chipText && <span className="included-text">{chipText}</span>}
          </div>
        </div>

        <div className="px-4">
          {features.map((feature, i) => (
            <div key={feature + i} className="d-flex align-items-center mb-3">
              <div className="feature-check-icon">
                <CheckIcon />
              </div>
              <p className="program-card-feature mb-0 fs-3">{feature}</p>
            </div>
          ))}
        </div>
      </div>
      <div className="d-flex flex-column align-items-center mt-6 ">
        <p className="ff-inter-light text-center fs-2">
          We will bill your insurance for services including visits, lab work,
          imaging, and prescription medications.
        </p>
        <PillButton
          className="continue-button justify-content-between position-relative mt-2 align-self-center"
          onClick={handleClick}
        >
          <div>Continue</div>
          <div className="continue-button-arrow">
            <ChevronRight />
          </div>
        </PillButton>
      </div>
    </div>
  )
}

export default ProgramCard
