import { useCallback, useMemo, useState } from 'react'
import {
  CardElement,
  CardElementProps,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js'
import {
  PaymentMethodResult,
  StripeCardElement,
  StripeCardElementChangeEvent,
  StripeError,
} from '@stripe/stripe-js'
import { Col, Form, Row } from 'react-bootstrap'
import { Formik, FormikHelpers } from 'formik'
import { getCode } from 'country-list'
import { ReactComponent as RightIcon } from 'assets/images/chevron-right.svg'
import { PillButton } from 'components/Button'
import TextField from 'components/TextField'
import Loader from 'components/Loader'
import InputContainer from 'hoc/InputContainer'
import { paymentFormNoTNCSchema, paymentFormSchema } from './utils'
import { useHistory } from 'react-router-dom'
import { RoutePaths } from 'containers/Core/Routes'
import AddressAutocomplete from 'components/AddressAutocomplete'
import { getAddressState, getCountry, getPostalCode } from './utils'
import { useOnboardingContext } from 'context/OnboardingContext/OnboardingProvider'
import {
  PromoCode,
  usePromoCodeContext,
} from 'context/PromoCodeContext/PromoCodeProvider'
import usePayment from 'hooks/usePayment/usePayment'
import { useAlert } from 'context/AlertContext/AlertContextProvider'
import CheckoutDetails from './CheckoutDetails'
import { Amplitude } from 'utils/amplitude.utils'
import { PROGRAMS } from 'utils/program.utils'

const rowProps = {
  className: 'py-1',
}

export interface PaymentFormValues {
  firstName: string
  lastName: string
  address: string
  city: string
  state: string
  zip: string
  country: string
  paymentInformationConsent: boolean
}

const initialValues: PaymentFormValues = {
  firstName: '',
  lastName: '',
  address: '',
  city: '',
  state: '',
  zip: '',
  country: '',
  paymentInformationConsent: false,
}

interface CheckoutFormProps {
  isDirectFlow?: boolean
}

const CheckoutForm: React.FC<CheckoutFormProps> = ({ isDirectFlow }) => {
  const stripe = useStripe()
  const history = useHistory()
  const elements = useElements()
  const { setAlertText } = useAlert()
  const [error, setError] = useState<StripeError>()
  const [cardComplete, setCardComplete] = useState(false)
  const { promoCode } = usePromoCodeContext()
  const {
    selectedPlan,
    markPaymentComplete,
    selectedProgram,
  } = useOnboardingContext()
  // eslint-disable-next-line
  const {
    createPaymentIntent,
    processCardPayment,
    attachPaymentMethod,
  } = usePayment()

  const cardElementOptions: CardElementProps['options'] = {
    style: {
      base: {
        fontSize: '16px',
        fontWeight: 300,
      },
    },
    hidePostalCode: true,
  }

  const onCardChange = (e: StripeCardElementChangeEvent) => {
    setError(e.error)
    setCardComplete(e.complete)
  }

  const onSuccess = useCallback(async (promoCode?: PromoCode) => {
    markPaymentComplete()

    return history.push(
      `${RoutePaths.PAYMENT_COMPLETE}${promoCode ? '?p=1' : ''}`
    )
  }, [])

  const startTime = useMemo(() => {
    return Date.now()
  }, [])

  const checkBoxText = useMemo(() => {
    if (
      selectedProgram === PROGRAMS.INSURANCE ||
      selectedProgram === PROGRAMS.INSURANCE_DIAGNOSTIC
    ) {
      return `By checking this box you acknowledge that your payment information will be kept on file for outstanding charges such as copays or coinsurance${
        selectedProgram === PROGRAMS.INSURANCE
          ? ' and for subscription auto-renewal.'
          : '.'
      }`
    } else {
      return `By checking this box you acknowledge that your payment information will be kept on file for outstanding charges${
        selectedProgram !== PROGRAMS.MNC
          ? ' and for subscription auto-renewal.'
          : '.'
      }`
    }
  }, [selectedProgram])

  const onSubmitHandler = async (
    {
      firstName,
      lastName,
      address,
      city,
      zip,
      state,
      country,
    }: PaymentFormValues,
    { setSubmitting, setErrors }: FormikHelpers<PaymentFormValues>
  ) => {
    if (!selectedPlan) {
      setSubmitting(false)
      return setAlertText(
        `You have not selected a plan yet. Please return to ${
          process.env.REACT_APP_URL
        }/${
          isDirectFlow
            ? RoutePaths.DIRECT_PAYMENT_PLANS
            : RoutePaths.PAYMENT_PLANS
        }${window.location.search}`,
        'Hang on a second'
      )
    }

    // Temporarily hardcoding for "United States"
    const countryCode =
      country.length === 2
        ? country
        : country === 'United States'
        ? 'US'
        : getCode(country)

    if (!countryCode) {
      setErrors({ country: 'Enter a valid country name' })
      setSubmitting(false)
      return
    }

    const { error, paymentMethod } = (await stripe?.createPaymentMethod({
      type: 'card',
      card: elements?.getElement(CardElement) as StripeCardElement,
      billing_details: {
        name: firstName + ' ' + lastName,
        address: {
          line1: address,
          city,
          postal_code: zip,
          state,
          country: countryCode,
        },
      },
    })) as PaymentMethodResult

    if (error) {
      setError(error)
      setSubmitting(false)
      return
    }

    if (!paymentMethod) {
      return setAlertText(
        'Card could not be verified. Please try again.',
        'An Error Occurred'
      )
    }

    Amplitude.userEntersPayment(Math.round((Date.now() - startTime) / 1000))

    const paymentIntentResponse = await createPaymentIntent(
      selectedPlan,
      promoCode?.code,
      isDirectFlow
    ).catch(({ error }) => {
      setError(error)
      setSubmitting(false)
      setAlertText(error, 'An Error Occurred')
      return null
    })

    if (paymentIntentResponse === null) {
      return
    }

    const { client_secret } = paymentIntentResponse

    if (client_secret) {
      // Attach and process
      try {
        await attachPaymentMethod(paymentMethod.id)
      } catch (err) {
        console.error('Unable to attach payment method')
        console.error(err)
      } finally {
        try {
          const isSuccess = await processCardPayment(
            client_secret,
            paymentMethod.id
          )
            .then(() => true)
            .catch((error) => {
              setAlertText(error.error, 'An Error Occurred')
              throw error.error
            })

          if (isSuccess) {
            onSuccess(promoCode)
          }
        } catch {
          setSubmitting(false)
        }
      }
    } else {
      // No payment to process so just attach payment method
      await attachPaymentMethod(paymentMethod.id).catch(() =>
        setAlertText(
          'Trial enabled but could not process credit card. Please update in the profile tab of your dashboard before billing begins.',
          'Warning'
        )
      )
      onSuccess(promoCode)
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={
        selectedProgram !== PROGRAMS.MNC
          ? paymentFormSchema
          : paymentFormNoTNCSchema
      }
      onSubmit={onSubmitHandler}
    >
      {({
        values,
        touched,
        errors,
        isSubmitting,
        handleSubmit,
        handleChange,
        setValues,
      }) => {
        const {
          firstName,
          lastName,
          address,
          zip,
          state,
          country,
          city,
          paymentInformationConsent,
        } = values

        const onZipSelect = ({ address_components }: Result) => {
          const zip = getPostalCode(address_components)
          const state = getAddressState(address_components, true)
          const country = getCountry(address_components)

          setValues({ ...values, zip, state, country })
        }

        const onStateSelect = ({ address_components }: Result) => {
          const state = getAddressState(address_components, true)
          const country = getCountry(address_components)

          setValues({ ...values, state, country })
        }

        const onCountrySelect = ({ address_components }: Result) => {
          const country = getCountry(address_components)

          setValues({ ...values, country })
        }

        return (
          <div className="d-flex justify-content-center align-items-center">
            <form
              onSubmit={handleSubmit}
              className="checkout-form d-flex justify-content-center"
            >
              <div className="checkout-form-sub checkout-form-sub-1">
                <h2 className="mt-5 fs-7 header">Payment Details</h2>
                <Row {...rowProps}>
                  {error?.message && (
                    <p className="text-danger text-center">{error?.message}</p>
                  )}
                </Row>
                <Row {...rowProps}>
                  <Col>
                    <TextField
                      containerClassName="text-capitalize"
                      label="First Name"
                      controlId="firstName"
                      error={
                        errors.firstName && touched.firstName
                          ? errors.firstName
                          : ''
                      }
                      value={firstName}
                      onChange={handleChange}
                    />
                  </Col>
                  <Col>
                    <TextField
                      label="Last Name"
                      controlId="lastName"
                      error={
                        errors.lastName && touched.lastName
                          ? errors.lastName
                          : ''
                      }
                      value={lastName}
                      onChange={handleChange}
                    />
                  </Col>
                </Row>
                <Row {...rowProps} className="py-2">
                  <Col>
                    <InputContainer label="Card Information">
                      <div id="card" className="card-element-container">
                        <CardElement
                          options={cardElementOptions}
                          onChange={onCardChange}
                        />
                      </div>
                    </InputContainer>
                  </Col>
                </Row>
                <Row {...rowProps}>
                  <Col>
                    <TextField
                      label="Billing Address"
                      controlId="address"
                      error={
                        errors.address && touched.address ? errors.address : ''
                      }
                      value={address}
                      onChange={handleChange}
                    />
                  </Col>
                </Row>
                <Row {...rowProps}>
                  <Col>
                    <TextField
                      placeholder="City"
                      controlId="city"
                      error={errors.city && touched.city ? errors.city : ''}
                      value={city}
                      onChange={handleChange}
                    />
                  </Col>
                  <Col>
                    <AddressAutocomplete
                      placeholder="State"
                      controlId="state"
                      error={errors.state && touched.state ? errors.state : ''}
                      value={state}
                      onChange={handleChange}
                      onSelect={onStateSelect}
                    />
                  </Col>
                </Row>
                <Row {...rowProps}>
                  <Col>
                    <AddressAutocomplete
                      placeholder="Zip"
                      controlId="zip"
                      type="number"
                      error={errors.zip && touched.zip ? errors.zip : ''}
                      value={zip}
                      onChange={handleChange}
                      onSelect={onZipSelect}
                      noArrow
                    />
                  </Col>
                  <Col>
                    <AddressAutocomplete
                      placeholder="Country"
                      controlId="country"
                      error={
                        errors.country && touched.country ? errors.country : ''
                      }
                      value={country}
                      onChange={handleChange}
                      onSelect={onCountrySelect}
                    />
                  </Col>
                </Row>
              </div>
              <div className="checkout-form-sub checkout-form-sub-2">
                <h2 className="mt-5 fs-7 header">Summary</h2>
                <CheckoutDetails />
                <Form.Group
                  className="tnc-container d-flex align-items-start"
                  controlId="paymentInformationConsent"
                >
                  <Form.Check
                    checked={paymentInformationConsent}
                    onChange={handleChange}
                  />
                  <Form.Label className="fs-3 mx-3 text-transform-none">
                    {checkBoxText}
                  </Form.Label>
                </Form.Group>
                <Row {...rowProps} className="py-3">
                  <Col className="d-flex justify-content-center">
                    <PillButton
                      className="checkout-button position-relative justify-content-between w-100"
                      type="submit"
                      block
                      disabled={
                        isSubmitting ||
                        !(
                          cardComplete &&
                          stripe &&
                          firstName &&
                          lastName &&
                          zip &&
                          state &&
                          country &&
                          city &&
                          paymentInformationConsent
                        )
                      }
                    >
                      {isSubmitting ? (
                        <Loader />
                      ) : (
                        <>
                          <div>Submit</div>
                          <div className="continue-button">
                            <RightIcon />
                          </div>
                        </>
                      )}
                    </PillButton>
                  </Col>
                </Row>
                <Row className="py-3">
                  <Col className="d-flex justify-content-center">
                    {promoCode?.discount?.subtitle && (
                      <p>*{promoCode.discount.subtitle}</p>
                    )}
                  </Col>
                </Row>
              </div>
            </form>
          </div>
        )
      }}
    </Formik>
  )
}

export default CheckoutForm
