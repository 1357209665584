import React, { useEffect, useState } from 'react'
import zxcvbn from 'zxcvbn'
import * as Yup from 'yup'
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  Heading,
  Text,
  Flex,
  chakra,
  ChakraProvider,
  useToast,
} from '@chakra-ui/react'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import validator from 'validator'
import useUser from 'hooks/useUser/useUser'
import { useStytch } from '@stytch/react'
import { RoutePaths } from 'containers/Core/Routes'
import PasswordInput from 'components/PasswordInput'
import OnboardingContainer from '../OnboardingContainer'

interface SignupProps {
  redirectPath?: string
}

interface FormData {
  email: string
  password: string
  phoneNumber: string
}

const Signup: React.FC<SignupProps> = ({ redirectPath }) => {
  const [passwordStrength, setPasswordStrength] = useState(0)
  const { createUser, checkUserEligibilityPreSignup } = useUser()
  const stytchClient = useStytch()
  const toast = useToast()
  const params = new URLSearchParams(window.location.search)
  const emailParam = params.get('email')
  const redirectUri =
    window.location.origin + redirectPath + window.location.search ??
    RoutePaths.ONBOARDING

  const initialValues: FormData = {
    email: emailParam ?? '',
    password: '',
    phoneNumber: '',
  }

  const validationSchema = Yup.object({
    email: Yup.string()
      .email('Invalid email address')
      .required('Email is required'),
    password: Yup.string()
      .min(8, 'Password must be at least 8 characters long')
      .required('Password is required'),
  })

  const handlePasswordChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    // eslint-disable-next-line @typescript-eslint/ban-types
    setFieldValue: Function
  ) => {
    const strength = zxcvbn(e.target.value).score
    setPasswordStrength(strength)
    setFieldValue('password', e.target.value)
  }

  const onSubmit = async (values: FormData) => {
    const params = {
      email: values.email,
      password: values.password,
      trusted_metadata: {
        phoneNumber: values.phoneNumber,
      },
      session_duration_minutes: 60,
    }

    try {
      const eligibility = await checkUserEligibilityPreSignup(values.email)

      if (eligibility.error) {
        toast({
          title: 'Error',
          description: eligibility.error,
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
        return
      }

      const stytchUser = await stytchClient.passwords.create(params)
      const response = await createUser({
        email: stytchUser.user.emails[0].email,
        authId: stytchUser.user_id,
        phoneNumber: values.phoneNumber,
      })

      if (response) {
        window.location.href = redirectUri
      } else {
        toast({
          title: 'Error',
          description: 'Something went wrong. Please try again.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      }
    } catch (error) {
      toast({
        title: 'Error',
        description: (error as Error).message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    }
  }

  return (
    <OnboardingContainer>
      <ChakraProvider>
        <Flex
          direction="column"
          justifyContent="center"
          alignItems="center"
          pt={10}
        >
          <Box bg="white" p={8} borderRadius="lg" boxShadow="lg" width="sm">
            <Heading color="gray.700" as="h4" size="m">
              Ready to select your plan?
            </Heading>
            <Text fontSize="sm" mb={6}>
              Based on your answers and location, we&apos;ll recommend the best
              plan for you.
              <br />
              Please create an account below to save your progress
            </Text>

            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {({ setFieldValue }) => (
                <Form>
                  <FormControl mb={4} isRequired>
                    <FormLabel htmlFor="email">Email</FormLabel>
                    <Field as={Input} id="email" name="email" type="email" />
                    <ErrorMessage name="email" component={FormErrorMessage} />
                  </FormControl>

                  <FormControl mb={4} isRequired>
                    <FormLabel htmlFor="password">Password</FormLabel>
                    <PasswordInput
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handlePasswordChange(e, setFieldValue)
                      }
                    />

                    <Box mt={2} mb={4}>
                      <Flex height="5px">
                        <chakra.span
                          flex="1"
                          bg={passwordStrength === 1 ? 'red.500' : 'gray.300'}
                        />
                        <chakra.span
                          flex="1"
                          bg={
                            passwordStrength === 2 ? 'orange.500' : 'gray.300'
                          }
                        />
                        <chakra.span
                          flex="1"
                          bg={
                            passwordStrength === 3 ? 'yellow.500' : 'gray.300'
                          }
                        />
                        <chakra.span
                          flex="1"
                          bg={passwordStrength === 4 ? 'green.500' : 'gray.300'}
                        />
                      </Flex>
                      {passwordStrength > 0 && passwordStrength < 2 && (
                        <Text color="red.500" fontSize="sm">
                          This is a top-10 common password.
                        </Text>
                      )}
                    </Box>
                  </FormControl>
                  <FormControl mb={4} isRequired>
                    <FormLabel htmlFor="password">Phone number</FormLabel>
                    <Field
                      as={Input}
                      id="phoneNumber"
                      name="phoneNumber"
                      type="tel"
                      validate={(value: string) => {
                        let error

                        if (validator.isMobilePhone(value) === false) {
                          error = 'Must be a valid phone number'
                        }

                        return error
                      }}
                    />
                    <ErrorMessage name="phoneNumber">
                      {(msg) => <div>{msg}</div>}
                    </ErrorMessage>
                  </FormControl>

                  <Button
                    type="submit"
                    backgroundColor={'var(--blue-light)'}
                    color="white"
                    width="full"
                    mt={4}
                    _hover={{
                      backgroundColor: 'var(--blue-dark-hover)',
                    }}
                  >
                    Continue
                  </Button>
                </Form>
              )}
            </Formik>
          </Box>
        </Flex>
      </ChakraProvider>
    </OnboardingContainer>
  )
}

export default Signup
