import { CalendarDetails } from 'containers/Scheduling/SuccessScreen'
import { formatInTimeZone } from 'date-fns-tz'
import momentTZ from 'moment-timezone'

export const formatDate = (
  date: Date,
  format?: 'mm/dd/yyyy' | 'locale-mm/dd/yyyy' | 'mm.dd.yyyy'
): string => {
  const temp = date.toISOString().split('T')[0]
  const [year, month, day] = temp.split(/-/g)

  switch (format) {
    case 'mm.dd.yyyy':
      return `${month}.${day}.${year}`
    case 'mm/dd/yyyy':
      return `${month}/${day}/${year}`
    case 'locale-mm/dd/yyyy':
    default:
      return date.toLocaleDateString()
  }
}

/**
 * Format the time portion of a date to be
 *     HH:MM AM/PM (using local time)
 * @param date
 * @returns
 */
export const formatTime = (date: Date): string => {
  const h = date.getHours()
  const m = date.getMinutes()
  const hours = h % 12 === 0 ? 12 : h % 12
  const minutes = m < 10 ? `0${m}` : m
  return `${hours}:${minutes} ${h > 12 ? 'PM' : 'AM'}`
}

/**
 * Returns the GMT offset of a time zone as a number
 * @param timeZone
 * @returns
 */
export const parseGmtOffset = (timeZone: string): number => {
  const gmtOffset = momentTZ.tz(timeZone).format('Z')
  return parseInt(gmtOffset.split(':')[0], 10)
}

/**
 * Returns the city (and state) name of a time zone
 * @param timeZone
 * @returns
 */
export const formatCityName = (timeZone: string): string => {
  const temp = timeZone.split('/')
  temp.shift()
  return temp.join(' - ').replace(/_/g, ' ')
}

/**
 * Format a time zone as (GMT {offset}) - Eastern Time - City
 * @param timeZone
 * @returns
 */
export const formatTimeZone = (timeZone: string): string => {
  const abbrs: Record<string, string> = {
    EST: 'Eastern Time',
    EDT: 'Eastern Time',
    CST: 'Central Time',
    CDT: 'Central Time',
    MST: 'Mountain Time',
    MDT: 'Mountain Time',
    PST: 'Pacific Time',
    PDT: 'Pacific Time',
    HDT: 'Hawaii Time',
    HST: 'Hawaii Time',
    AKDT: 'Alaska Time',
    AKST: 'Alaska Time',
  }

  momentTZ.fn.zoneName = function () {
    const abbr = this.zoneAbbr()
    return abbrs[abbr] || abbr
  }

  const gmtOffset = momentTZ.tz(timeZone).format('Z')
  const zoneName = momentTZ.tz(timeZone).format('zz')
  return `(GMT ${gmtOffset}) ${zoneName} - ${formatCityName(timeZone)}`
}

/**
 * List all US time zones
 * @returns
 */
export const usTimeZones = (): string[] => momentTZ.tz.zonesForCountry('US')

export const toICS = (id: string, calendarDetails: CalendarDetails): string => {
  const DATE_TIME_FORMAT = "yyyyMMdd'T'HHmmss'Z'"
  const SEPARATOR = navigator.appVersion.indexOf('Win') !== -1 ? '\r\n' : '\n'

  const dtstamp = formatInTimeZone(new Date(), 'GMT', DATE_TIME_FORMAT)
  const dtstart = formatInTimeZone(
    calendarDetails.dateTime,
    'GMT',
    DATE_TIME_FORMAT
  )
  const dtend = formatInTimeZone(
    calendarDetails.endDateTime,
    'GMT',
    DATE_TIME_FORMAT
  )

  const calendarEvent = [
    'BEGIN:VCALENDAR',
    'PRODID:Calendar',
    'VERSION:2.0',
    'BEGIN:VEVENT',
    `UID:${id}`,
    'CLASS:PUBLIC',
    `DESCRIPTION:${calendarDetails.description}`,
    `DTSTAMP;VALUE=DATE-TIME:${dtstamp}`,
    `DTSTART;VALUE=DATE-TIME:${dtstart}`,
    `DTEND;VALUE=DATE-TIME:${dtend}`,
    `LOCATION:${calendarDetails.location}`,
    `SUMMARY;LANGUAGE=en-us:${calendarDetails.title}`,
    'TRANSP:TRANSPARENT',
    'END:VEVENT',
    'END:VCALENDAR',
  ]

  return calendarEvent.join(SEPARATOR)
}

export const toGCal = (calendarDetails: CalendarDetails): string => {
  const DATE_TIME_FORMAT = "yyyyMMdd'T'HHmmss'Z'"
  const startTime = formatInTimeZone(
    calendarDetails.dateTime,
    'GMT',
    DATE_TIME_FORMAT
  )
  const endTime = formatInTimeZone(
    calendarDetails.endDateTime,
    'GMT',
    DATE_TIME_FORMAT
  )

  return `https://calendar.google.com/calendar/u/0/r/eventedit?text=${encodeURIComponent(
    calendarDetails.title
  )}&dates=${startTime}/${endTime}&details=${encodeURIComponent(
    calendarDetails.description
  )}&location=${encodeURIComponent(calendarDetails.location)}`
}
