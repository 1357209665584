import { Field } from 'formik'
import {
  Input,
  Select,
  GridItem,
  FormLabel,
  FormControl,
} from '@chakra-ui/react'
import AddressAutocomplete2 from 'components/AddressAutocomplete/version2'
import { STATES } from 'containers/Core/utils'
import { AddressInfo } from 'hooks/useTimeline/useActions'

interface AddressProps {
  formIsDisabled?: boolean
  initialValues?: AddressInfo | null | undefined
  index?: number
}

const Address = ({ formIsDisabled, index = 0 }: AddressProps) => {
  return (
    <>
      <GridItem>
        <FormLabel fontWeight={300} color={'var(--gray-dark)'}>
          Address Line 1
        </FormLabel>
        <AddressAutocomplete2
          name={`addresses[${index}].address_line1`}
          formIsDisabled={formIsDisabled}
          index={index ?? 0}
        />
      </GridItem>
      <GridItem>
        <FormLabel fontWeight={300} color={'var(--gray-dark)'}>
          Address Line 2
        </FormLabel>
        <FormControl>
          <Field
            as={Input}
            name={`addresses[${index}].address_line2`}
            isDisabled={formIsDisabled}
          />
        </FormControl>
      </GridItem>
      <GridItem>
        <FormLabel fontWeight={300} color={'var(--gray-dark)'}>
          City
        </FormLabel>
        <FormControl isRequired>
          <Field
            as={Input}
            name={`addresses[${index}].city`}
            isDisabled={formIsDisabled}
          />
        </FormControl>
      </GridItem>
      <GridItem>
        <FormLabel fontWeight={300} color={'var(--gray-dark)'}>
          State
        </FormLabel>
        <FormControl isRequired>
          <Field
            as={Select}
            name={`addresses[${index}].state`}
            isDisabled={formIsDisabled}
          >
            {STATES.map((state) => (
              <option key={state.value} value={state.value}>
                {state.label}
              </option>
            ))}
          </Field>
        </FormControl>
      </GridItem>
      <GridItem>
        <FormLabel fontWeight={300} color={'var(--gray-dark)'}>
          ZIP code
        </FormLabel>
        <FormControl isRequired>
          <Field
            as={Input}
            name={`addresses[${index}].zip`}
            isDisabled={formIsDisabled}
          />
        </FormControl>
      </GridItem>
    </>
  )
}

export default Address
