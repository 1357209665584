import { useStytch, useStytchSession } from '@stytch/react'
import {
  CareTimeline as CareTimelineRoute,
  QueryKey as TimelineQueryKey,
} from 'api/timeline/routes'
import { useQuery } from 'react-query'

export type CareTimeline = {
  id: string
  description?: string
  items: { text: string; description: string }[]
}

interface UseCareTimelineInterface {
  isLoading: boolean
  error: unknown
  careTimeline?: CareTimeline
}

const useCareTimeline = (): UseCareTimelineInterface => {
  const stytch = useStytch()
  const { session } = useStytchSession()

  const fetchCareTimeline = async (): Promise<CareTimeline> => {
    const defaultCareTimeline = { id: '', items: [] }

    if (!session) {
      return defaultCareTimeline
    }

    const tokens = stytch.session.getTokens()
    const accessToken = tokens ? tokens.session_jwt : undefined
    return fetch(`${process.env.REACT_APP_SERVER_URL}${CareTimelineRoute}`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    })
      .then(async (res) => {
        const data = await res.json()

        if (res.ok) {
          return data
        } else {
          return defaultCareTimeline
        }
      })
      .catch(() => {
        return defaultCareTimeline
      })
  }

  const { isLoading, error, data: careTimeline } = useQuery(
    TimelineQueryKey,
    fetchCareTimeline,
    {
      staleTime: Infinity,
    }
  )

  return {
    isLoading,
    error,
    careTimeline,
  }
}

export default useCareTimeline
