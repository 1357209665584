import useActions from 'hooks/useTimeline/useActions'
import { useCallback } from 'react'
import UploadInsuranceCardForm from '../../MyHealth/Insurance/UploadInsuranceCardForm'
import { CardFormProps } from '.'

const ShareInsuranceForm: React.FC<CardFormProps> = ({
  action,
  actionDefinition,
}) => {
  const { handleCompleteAction } = useActions()

  const onSubmit = useCallback(() => {
    handleCompleteAction(action.id)
  }, [action, handleCompleteAction])

  return (
    <UploadInsuranceCardForm
      showNoInsurance={true}
      onSubmit={onSubmit}
      buttonColor="green"
      buttonText={actionDefinition.buttonText}
    />
  )
}

export default ShareInsuranceForm
