import { Button } from '@chakra-ui/react'

interface ChakraPrimaryButtonProps {
  children: string
  onClick?: () => void
  leftIcon?:
    | React.ReactElement<unknown, string | React.JSXElementConstructor<unknown>>
    | undefined
  rightIcon?:
    | React.ReactElement<unknown, string | React.JSXElementConstructor<unknown>>
    | undefined
  color?: string
  variant?: string
  width?: string
  alignSelf?: string
  _hover?: object
  backgroundColor?: string
  type?: 'button' | 'submit' | 'reset' | undefined
  styleProps?: object
  disabled?: boolean
}

const ChakraPrimaryButton = ({
  color = 'white',
  backgroundColor = 'var(--blue-dark)',
  _hover = { backgroundColor: 'var(--blue-dark-hover)', cursor: 'pointer' },
  variant = 'solid',
  width = 'auto',
  type = 'button',
  styleProps,
  disabled,
  ...props
}: ChakraPrimaryButtonProps) => {
  return (
    <Button
      leftIcon={props.leftIcon}
      rightIcon={props.rightIcon}
      color={color}
      variant={variant}
      width={width}
      alignSelf={props.alignSelf}
      _hover={_hover}
      onClick={props.onClick}
      backgroundColor={backgroundColor}
      type={type}
      isDisabled={disabled}
      {...styleProps}
    >
      {props.children}
    </Button>
  )
}

export default ChakraPrimaryButton
