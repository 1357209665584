import { PrimaryButton } from 'components/Button'
import useActions from 'hooks/useTimeline/useActions'
import { useCallback } from 'react'

const AtHomeTestingForm: React.FC = () => {
  const { handleRespondAtHomeTesting } = useActions()

  const handleClick = useCallback(
    (response: 'home' | 'labcorp' | 'quest' | 'unsure') => {
      handleRespondAtHomeTesting(response)
    },
    []
  )
  const handleQuestClick = useCallback(() => handleClick('quest'), [])
  const handleUnsureClick = useCallback(() => handleClick('unsure'), [])
  const handleLabCorpClick = useCallback(() => handleClick('labcorp'), [])

  return (
    <div className="d-flex justify-content-flex-start flex-column">
      <div style={{ height: '15px' }}></div>
      <PrimaryButton
        variant="green"
        onClick={handleLabCorpClick}
        className="spaced-capital-letters fs-2"
      >
        At LabCorp
      </PrimaryButton>
      <div style={{ height: '15px' }}></div>
      <PrimaryButton
        variant="green"
        onClick={handleQuestClick}
        className="spaced-capital-letters fs-2"
      >
        At Quest
      </PrimaryButton>
      <div style={{ height: '15px' }}></div>
      <PrimaryButton
        variant="green"
        onClick={handleUnsureClick}
        className="spaced-capital-letters fs-2"
      >
        Other/Unsure
      </PrimaryButton>
    </div>
  )
}

export default AtHomeTestingForm
