import { PrimaryButton } from 'components/Button'
import useActions from 'hooks/useTimeline/useActions'
import { useCallback } from 'react'
import { useOnboardingContext } from 'context/OnboardingContext/OnboardingProvider'
import JoinModal from 'components/JoinModal'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import { PROGRAMS, getProgramName } from 'utils/program.utils'
import { useAlert } from 'context/AlertContext/AlertContextProvider'
import { CardFormProps } from '.'

const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || ''
)

const JoinNutritionOnlyProgramForm: React.FC<CardFormProps> = ({ action }) => {
  const { setAlertText } = useAlert()
  const { handleCompleteAction } = useActions()
  const { selectedProgram, setSelectedProgram } = useOnboardingContext()

  const handleCloseModal = useCallback(() => setSelectedProgram(undefined), [])

  const handleOpenModal = useCallback(
    () => setSelectedProgram(PROGRAMS.NUTRITION_ONLY),
    []
  )

  const onSuccess = useCallback(() => {
    handleCompleteAction(action.id)
    setAlertText('We are configuring your dashboard now.', 'Payment Success!')
  }, [])

  return (
    <Elements stripe={stripePromise}>
      <JoinModal
        program={selectedProgram}
        handleClose={handleCloseModal}
        onSuccess={onSuccess}
      />
      <PrimaryButton
        variant="green"
        onClick={handleOpenModal}
        className="spaced-capital-letters fs-2"
      >
        Join {getProgramName(PROGRAMS.NUTRITION_ONLY)}
      </PrimaryButton>
    </Elements>
  )
}

export default JoinNutritionOnlyProgramForm
