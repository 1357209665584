import { Appointment } from 'hooks/useAppointments/useAppointments'
import useAppointmentDefinitions from 'hooks/useAppointments/useAppointmentDefinitions'
import React, { useMemo } from 'react'
import DashboardCard from './Card'
import { formatAppointmentText } from 'utils/appointments.utils'
import { RoutePaths } from 'containers/Core/Routes'

interface AppointmentCardProps {
  appointment: Appointment
}

const AppointmentCard: React.FC<AppointmentCardProps> = ({ appointment }) => {
  const { appointmentDefinitions } = useAppointmentDefinitions()

  const appointmentDefinition = useMemo(() => {
    let code = ''

    if (appointment.isCancelled) {
      code = 'cancelled'
    } else if (new Date(appointment.time) > new Date()) {
      code = 'scheduled'
    } else {
      code = 'complete'
    }

    return appointmentDefinitions?.find((d) => d.id === code)
  }, [appointmentDefinitions])

  if (!appointmentDefinition) {
    return <></>
  } else {
    return (
      <DashboardCard
        icon={appointmentDefinition.icon}
        title={formatAppointmentText(appointmentDefinition.title, appointment)}
        description={formatAppointmentText(
          appointmentDefinition.description,
          appointment
        )}
        buttonLink={{
          link: `${RoutePaths.SCHEDULING}?appointment=${appointment.sourceId}`,
          target: '_self',
        }}
        buttonText={appointmentDefinition.buttonText ?? ''}
        displayButton={appointmentDefinition.buttonText ? true : false}
        disableButton={false}
      />
    )
  }
}

export default AppointmentCard
