import { WithAccountProps } from 'hoc/withAccount'
import { Col, Container, Row } from 'react-bootstrap'
import Appointments from './Appointments'
import ComingUp from './ComingUp'
import Completed from './Completed'
import Timeline from './Timeline'
import { memo } from 'react'
import useBanner, { BannerConstants } from 'hooks/useBanner/useBanner'
import withAccountStatus from 'hoc/withAccountStatus'

import './styles.scss'

const Dashboard: React.FC<WithAccountProps> = (props) => {
  const { banner } = useBanner(BannerConstants.DASHBOARD)

  return (
    <Container
      fluid
      className={`px-0 ${
        banner?.bannerStatus ? 'mt-lg-2' : 'mt-lg-10'
      } dashboard`}
    >
      <Row className="h-100">
        <Col sm={12} xl={6} className="dashboard-col-l">
          <ComingUp {...props} />
          <Completed {...props} />
          <Appointments />
        </Col>
        <Col sm={12} xl={6}>
          <Timeline />
        </Col>
      </Row>
    </Container>
  )
}

export default withAccountStatus(memo(Dashboard))
