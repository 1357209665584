import { memo, useCallback } from 'react'
import { ReactComponent as CheckIcon } from 'assets/images/check.svg'
import { useSchedulingContext } from 'context/SchedulingContext/SchedulingProvider'
import momentTZ from 'moment-timezone'
import { ReactComponent as ChevronLeft } from 'assets/images/chevron-left.svg'
import { ReactComponent as ChevronRight } from 'assets/images/chevron-right.svg'
import AppLogo from 'components/AppLogo'
import { format } from 'date-fns'
import withAccount, { WithAccountProps } from 'hoc/withAccount'
import { utcToZonedTime } from 'date-fns-tz'
import { SecondaryButton } from 'components/Button'
import { useHistory } from 'react-router-dom'
import { RoutePaths } from 'containers/Core/Routes'
import { Skeleton, ChakraProvider } from '@chakra-ui/react'

const Navigation: React.FC<WithAccountProps> = ({ user }) => {
  const {
    appointmentType,
    isLoadingApptType,
    currentScreen,
    previousScreen,
  } = useSchedulingContext()
  const history = useHistory()

  const returnToDashboard = useCallback(
    () => history.push(RoutePaths.DASHBOARD),
    []
  )
  const isScreenAppointmentTypeSelect =
    currentScreen === 'appointmentTypeSelect'
  const shouldShowStepper =
    currentScreen !== 'success' && !isScreenAppointmentTypeSelect

  const showAppointmentInfo = !isLoadingApptType && !!appointmentType

  return (
    <ChakraProvider>
      <div className="px-3 px-lg-5 mt-3 mt-lg-5">
        <AppLogo />
        {currentScreen !== 'success' && (
          <div className="mt-5">
            <div
              className="d-flex align-items-center cursor-pointer mb-2"
              onClick={previousScreen}
            >
              <ChevronLeft className="nav-icon-small text-primary" />
              <p className="spaced-capital-letters mb-0 ml-2 text-primary">
                Back
              </p>
            </div>
            <p className="ff-inter-medium fs-6">
              {isScreenAppointmentTypeSelect &&
                'Please select your appointment type'}
              {!isScreenAppointmentTypeSelect && (
                <Skeleton isLoaded={showAppointmentInfo}>
                  {`${appointmentType?.name}, ${appointmentType?.duration} min`}
                </Skeleton>
              )}
            </p>
          </div>
        )}
        {currentScreen === 'success' && (
          <div className="mt-3">
            <p className="ff-inter-medium fs-6">
              Booking Completed <br />
              Successfully
            </p>
            <SecondaryButton
              variant="dark-blue"
              className="next-button"
              onClick={returnToDashboard}
            >
              <div>
                To Dashboard <ChevronRight className="ml-2" />
              </div>
            </SecondaryButton>
          </div>
        )}
        {shouldShowStepper && <MobileViewStepper />}
        {shouldShowStepper && <DesktopViewStepper user={user} />}
      </div>
    </ChakraProvider>
  )
}

const DesktopViewStepper: React.FC<WithAccountProps> = ({ user }) => {
  const { currentScreen, timeZone, dateTime, provider } = useSchedulingContext()
  const zonedTime = utcToZonedTime(dateTime ?? new Date(), timeZone ?? '')

  const getTimeZone = (): string => {
    return momentTZ.tz(timeZone ?? '').format('z [(GMT] Z[)]')
  }

  return (
    <div className=" d-flex align-items-start ml-3 mt-3 hide">
      <div className="d-flex flex-column align-items-center">
        <CustomCheckIcon checked={currentScreen !== 'location'} />
        <div
          className={`vertical-line ${
            currentScreen !== 'location' ? 'vertical-line-active' : ''
          }`}
        />
        <CustomCheckIcon
          checked={currentScreen === 'confirm' || currentScreen === 'success'}
        />
        <div
          className={`vertical-line ${
            currentScreen === 'confirm' || currentScreen === 'success'
              ? 'vertical-line-active'
              : ''
          }`}
        />
        <CustomCheckIcon checked={currentScreen === 'success'} />
      </div>
      <div className="d-flex flex-column justify-content-between align-items-stretch h-100 ml-3">
        <div className="height-139">
          <h1 className="ff-inter-medium fs-6">Location</h1>
          {currentScreen !== 'location' && (
            <span className="text-uppercase">
              {user.waitlistUser.state.fullName}, {getTimeZone()}
            </span>
          )}
        </div>
        <div className="height-139">
          <h1 className="ff-inter-medium fs-6">Appointment</h1>
          {(currentScreen === 'confirm' || currentScreen === 'success') && (
            <span className="text-uppercase">
              {`${format(zonedTime, 'EE')}, ${format(
                zonedTime,
                'MMMM'
              )} ${zonedTime.getDate()},`}{' '}
              <br />
              {`${format(zonedTime, 'p')}, ${provider?.first_name} ${
                provider?.last_name
              }`}
            </span>
          )}
        </div>
        <div className="height-139">
          <h1 className="ff-inter-medium fs-6">Confirmation</h1>
        </div>
      </div>
    </div>
  )
}

const MobileViewStepper: React.FC = () => {
  const { currentScreen } = useSchedulingContext()

  return (
    <div className="d-flex d-lg-none flex-column w-100 align-item-center justify-content-center  mt-4">
      <div className="d-flex justify-content-between align-items-center">
        <CustomCheckIcon checked={currentScreen !== 'location'} />
        <div
          className={`horizontal-line ${
            currentScreen !== 'location' ? 'horizontal-line-active' : ''
          }`}
        />
        <CustomCheckIcon
          checked={currentScreen === 'confirm' || currentScreen === 'success'}
        />
        <div
          className={`horizontal-line ${
            currentScreen === 'confirm' || currentScreen === 'success'
              ? 'horizontal-line-active'
              : ''
          }`}
        />
        <CustomCheckIcon checked={currentScreen === 'success'} />
      </div>
      <div className="d-flex flex-row justify-content-between align-items-start mt-2">
        <div className="width-139">
          <h1 className="ff-inter-medium fs-3">Location</h1>
        </div>
        <div className="width-139">
          <h1 className="ff-inter-medium fs-3">Appointment</h1>
        </div>
        <div className="width-139">
          <h1 className="ff-inter-medium fs-3">Confirmation</h1>
        </div>
      </div>
    </div>
  )
}

type CustomCheckIconProp = {
  checked?: boolean
}

const CustomCheckIcon: React.FC<CustomCheckIconProp> = ({ checked }) => {
  return (
    <div
      className={`check d-flex justify-content-center align-items-center text-white ${
        checked ? 'check-filled' : ''
      }`}
    >
      <CheckIcon />
    </div>
  )
}

export default memo(withAccount(Navigation))
