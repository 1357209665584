import useCareTimeline from 'hooks/useTimeline/useCareTimeline'
import { ReactComponent as InfoIcon } from 'assets/images/info-circle.svg'
import { memo, useState } from 'react'

import './styles.scss'

interface TimelineItemProps {
  item: { text: string; description: string }
  side: 'right' | 'left'
}

const TimelineItem: React.FC<TimelineItemProps> = ({ item, side }) => {
  const [showInfo, setShowInfo] = useState<boolean>(false)

  const toggleShowInfo = () => setShowInfo((current) => !current)

  return (
    <div className={`container ${side}`}>
      <div className="content">
        {showInfo && (
          <div className="content-info mb-2 py-2" onClick={toggleShowInfo}>
            {item.description}
          </div>
        )}
        {!showInfo && (
          <p>
            {item.text}
            <InfoIcon className="info-icon" onClick={toggleShowInfo} />
          </p>
        )}
      </div>
    </div>
  )
}

const Timeline: React.FC = () => {
  const { careTimeline } = useCareTimeline()

  return (
    <div className="timeline px-3 pb-3">
      <p className="h1 pt-2 fs-8">Your Care Timeline</p>
      <p>{careTimeline?.description}</p>
      <div className="timeline-line">
        {careTimeline?.items.map((item, i) => {
          const side = i % 2 === 0 ? 'right' : 'left'

          return <TimelineItem key={i} item={item} side={side} />
        })}
        <div className="container-spacer"></div>
      </div>
    </div>
  )
}

export default memo(Timeline)
