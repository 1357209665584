import React, { useCallback, useState } from 'react'
import { RoutePaths } from 'containers/Core/Routes'
import {
  Box,
  Button,
  Center,
  ChakraProvider,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  chakra,
  Text,
} from '@chakra-ui/react'
import { Formik, Form, Field } from 'formik'
import { useStytch } from '@stytch/react'
import AppLogo from 'components/AppLogo'
import PasswordInput from 'components/PasswordInput'
import zxcvbn from 'zxcvbn'

interface FormData {
  password: string
}

const PasswordReset: React.FC = ({}) => {
  const [passwordStrength, setPasswordStrength] = useState(0)

  const redirectUri = window.location.origin + RoutePaths.ONBOARDING
  const stytchClient = useStytch()

  const token = new URLSearchParams(window.location.search).get('token')

  const initialValues: FormData = {
    password: '',
  }

  const handlePasswordChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    // eslint-disable-next-line @typescript-eslint/ban-types
    setFieldValue: Function
  ) => {
    const strength = zxcvbn(e.target.value).score
    setPasswordStrength(strength)
    setFieldValue('password', e.target.value)
  }

  const resetPassword = useCallback(
    async (values: FormData) => {
      const response = await stytchClient.passwords.resetByEmail({
        token: token || '',
        password: values.password,
        session_duration_minutes: 60,
      })

      if (response.status_code === 200) {
        window.location.href = redirectUri
      }
    },
    [stytchClient]
  )

  return (
    <ChakraProvider>
      <Flex
        justify="center"
        align="center"
        height="100vh"
        backgroundColor="var(--gray-extra-light)"
      >
        <Box bg="white" p={8} borderRadius="lg" boxShadow="lg" width="sm">
          <Center mb={50}>
            <AppLogo color={'var(--blue-bright)'} />
          </Center>
          <Center>
            <Heading color="gray.700" as="h4" size="m" mb={8}>
              Reset your password
            </Heading>
          </Center>
          <Formik initialValues={initialValues} onSubmit={resetPassword}>
            {({ setFieldValue }) => (
              <Form>
                <FormControl mb={4} isRequired>
                  <FormLabel htmlFor="password">Password</FormLabel>
                  <PasswordInput
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      handlePasswordChange(e, setFieldValue)
                    }
                  />
                  <Box mt={2} mb={4}>
                    <Flex height="5px">
                      <chakra.span
                        flex="1"
                        bg={passwordStrength === 1 ? 'red.500' : 'gray.300'}
                      />
                      <chakra.span
                        flex="1"
                        bg={passwordStrength === 2 ? 'orange.500' : 'gray.300'}
                      />
                      <chakra.span
                        flex="1"
                        bg={passwordStrength === 3 ? 'yellow.500' : 'gray.300'}
                      />
                      <chakra.span
                        flex="1"
                        bg={passwordStrength === 4 ? 'green.500' : 'gray.300'}
                      />
                    </Flex>
                    {passwordStrength > 0 && passwordStrength < 2 && (
                      <Text color="red.500" fontSize="sm">
                        This is a top-10 common password.
                      </Text>
                    )}
                  </Box>
                </FormControl>

                <Button
                  type="submit"
                  backgroundColor={'var(--blue-light)'}
                  color="white"
                  width="full"
                  mt={4}
                  _hover={{
                    backgroundColor: 'var(--blue-dark-hover)',
                  }}
                >
                  Reset
                </Button>
              </Form>
            )}
          </Formik>
        </Box>
      </Flex>
    </ChakraProvider>
  )
}

export default PasswordReset
