import Loader from 'components/Loader'
import useAppointments from 'hooks/useAppointments/useAppointments'
import { useMemo, useState } from 'react'
import AppointmentCard from './AppointmentCard'

const Appointments: React.FC = () => {
  const { isLoading, appointments } = useAppointments()
  const [maxNumberShown, setMaxNumberShown] = useState<number>(3)

  const handleShowMore = () => setMaxNumberShown((n) => n + 3)
  const handleShowLess = () => setMaxNumberShown((n) => n - 3)

  const appointmentsToShow = useMemo(
    () => appointments.slice(0, maxNumberShown),
    [appointments, maxNumberShown]
  )

  return (
    <div>
      <p className="h1 py-2 fs-8">Appointments</p>
      {!isLoading &&
        appointments.length >= 1 &&
        appointments.map((appointment) => {
          return (
            <AppointmentCard key={appointment.id} appointment={appointment} />
          )
        })}
      {!isLoading && appointments.length >= 1 && (
        <div className="d-flex justify-content-between">
          <p className="show-more-less-text" onClick={handleShowLess}>
            {maxNumberShown === 3 ? '' : 'Show Less'}
          </p>
          <p className="show-more-less-text" onClick={handleShowMore}>
            {appointmentsToShow.length > maxNumberShown ? 'Show More' : ''}
          </p>
        </div>
      )}
      {!isLoading && appointments.length === 0 && (
        <div>
          <p>
            You have no appointments. When it is time to book your first
            appointment you will see it listed as an action above.
          </p>
        </div>
      )}
      {isLoading && <Loader />}
    </div>
  )
}

export default Appointments
