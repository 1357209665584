import { PrimaryButton } from 'components/Button'
import useActions from 'hooks/useTimeline/useActions'
import { useCallback } from 'react'
import { default as UploadLabsFormComponent } from '../../MyHealth/Labs/UploadLabsForm'
import { CardFormProps } from '.'

const UploadLabsForm: React.FC<CardFormProps> = ({
  action,
  actionDefinition,
}) => {
  const { handleCompleteAction } = useActions()

  const onSubmit = useCallback(() => {
    handleCompleteAction(action.id)
  }, [action, handleCompleteAction])

  return (
    <>
      <UploadLabsFormComponent
        onSubmit={onSubmit}
        buttonColor={'green'}
        buttonText={actionDefinition.buttonText}
      />
      <PrimaryButton
        variant="green"
        onClick={onSubmit}
        className="spaced-capital-letters fs-2 mt-3"
      >
        Complete Task - I have no labs to share
      </PrimaryButton>
    </>
  )
}

export default UploadLabsForm
