import { PrimaryButton } from 'components/Button'
import useActions from 'hooks/useTimeline/useActions'
import { useCallback } from 'react'
import { AppStoreUrl, PlayStoreUrl } from '../../constants'
import { CardFormProps } from '.'

const DownloadAppForm: React.FC<CardFormProps> = ({ action }) => {
  const { handleCompleteAction } = useActions()

  const handleClick = useCallback(() => {
    handleCompleteAction(action.id)
  }, [action.id])

  return (
    <div className="d-flex justify-content-between">
      <PrimaryButton
        onClick={handleClick}
        variant="green"
        className="spaced-capital-letters fs-2"
      >
        <a
          className="text-white"
          target="_blank'"
          href={AppStoreUrl}
          rel="noreferrer"
        >
          Download iOS
        </a>
      </PrimaryButton>
      <PrimaryButton
        onClick={handleClick}
        variant="green"
        className="spaced-capital-letters fs-2"
      >
        <a
          className="text-white"
          target="_blank"
          href={PlayStoreUrl}
          rel="noreferrer"
        >
          Download Android
        </a>
      </PrimaryButton>
    </div>
  )
}

export default DownloadAppForm
