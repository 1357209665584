import { FormControl, Input } from '@chakra-ui/react'
import { useFormikContext, useField } from 'formik'
import { useEffect } from 'react'

interface AddressAutocompleteProps {
  name: string
  label?: string
  formIsDisabled?: boolean
  index: number
}

type ExtendedGeocoderAddressComponent = google.maps.GeocoderAddressComponent & {
  [key: string]: string
}

function AddressAutocomplete2(props: AddressAutocompleteProps) {
  const { setFieldValue } = useFormikContext<unknown>()
  const [field, , helpers] = useField(props)

  useEffect(() => {
    const handlePlaceSelect = () => {
      const autocomplete = new (window as any).google.maps.places.Autocomplete(
        document.getElementById('autocomplete') as HTMLInputElement,
        { types: ['address'] }
      )

      autocomplete.addListener('place_changed', () => {
        const place = autocomplete.getPlace()
        const addressComponents = place?.address_components || []
        const componentForm: { [key: string]: string } = {
          street_number: 'short_name',
          route: 'long_name',
          locality: 'long_name',
          administrative_area_level_1: 'short_name',
          postal_code: 'short_name',
        }

        let address1 = ''
        let city = ''
        let state = ''
        let zip = ''

        for (const component of addressComponents) {
          const addressType = component.types[0]

          if (componentForm[addressType]) {
            const val = (component as ExtendedGeocoderAddressComponent)[
              componentForm[addressType]
            ]

            if (addressType === 'street_number') {
              address1 += val + ' '
            } else if (addressType === 'route') {
              address1 += val
            } else if (addressType === 'locality') {
              city = val
            } else if (addressType === 'administrative_area_level_1') {
              state = val
            } else if (addressType === 'postal_code') {
              zip = val
            }
          }
        }

        setFieldValue(`addresses[${props.index}].address_line1`, address1)
        setFieldValue(`addresses[${props.index}].city`, city)
        setFieldValue(`addresses[${props.index}].state`, state)
        setFieldValue(`addresses[${props.index}].zip`, zip)
        helpers.setValue(address1)
      })
    }

    if ((window as any).google) {
      handlePlaceSelect()
    }
  }, [setFieldValue, helpers])

  return (
    <FormControl isRequired>
      <Input
        {...field}
        {...props}
        id="autocomplete"
        placeholder="Enter your address"
        isDisabled={props.formIsDisabled}
      />
    </FormControl>
  )
}

export default AddressAutocomplete2
