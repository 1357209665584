import withAccount, { WithAccountProps } from 'hoc/withAccount'
import OnboardingContainer from '../OnboardingContainer'
import InsuranceInfoForm from './InsuranceForm'
import { useCallback } from 'react'
import { RoutePaths } from 'containers/Core/Routes'
import { useHistory } from 'react-router-dom'

import './styles.scss'
import { useOnboardingContext } from 'context/OnboardingContext/OnboardingProvider'
import useAppointmentTypeId from 'hooks/useAppointments/useAppointmentTypeId'
import { PROGRAMS } from 'utils/program.utils'
import useAppointments from 'hooks/useAppointments/useAppointments'

const InsuranceInfo: React.FC<WithAccountProps> = ({ user }) => {
  const history = useHistory()
  const { selectedProgram } = useOnboardingContext()
  const { appointments } = useAppointments()
  const {
    appointmentTypeId: fullProgramAppointmentTypeId,
  } = useAppointmentTypeId('Initial MD')
  const { appointmentTypeId: careTranferTypeId } = useAppointmentTypeId(
    'Care Transfer MD'
  )

  const handleOnSubmit = useCallback(() => {
    if (
      selectedProgram === PROGRAMS.INSURANCE ||
      selectedProgram === PROGRAMS.INSURANCE_DIAGNOSTIC ||
      user.stripeCustomer?.product.type === PROGRAMS.INSURANCE ||
      user.stripeCustomer?.product.type === PROGRAMS.INSURANCE_DIAGNOSTIC
    ) {
      const filteredAppointments = appointments.filter((a) => !a.isCancelled)
        .length
      const isReactivatedUser =
        user.accountStatus === 'ACTIVE' &&
        user.cancellationReason !== null &&
        filteredAppointments > 0

      const appointmentTypeId = (() => {
        if (isReactivatedUser) {
          return careTranferTypeId
        } else if (
          selectedProgram === PROGRAMS.INSURANCE ||
          user.stripeCustomer?.product.type === PROGRAMS.INSURANCE
        ) {
          return fullProgramAppointmentTypeId
        }

        return null
      })()

      if (appointmentTypeId) {
        return history.push(
          `${RoutePaths.SCHEDULING_REDIRECT}?type=${appointmentTypeId}`
        )
      }
    }

    history.push(`${RoutePaths.DASHBOARD}${window.location.search}`)
  }, [selectedProgram, fullProgramAppointmentTypeId])

  return (
    <OnboardingContainer>
      <div className="d-none conversion-email">{user?.email}</div>
      <div className="d-flex justify-content-center align-items-center">
        <div className="insurance-info d-flex justify-content-center align-items-center align-self-center my-4">
          <InsuranceInfoForm
            onSubmit={handleOnSubmit}
            title="Welcome to Allara"
          />
        </div>
      </div>
    </OnboardingContainer>
  )
}

export default withAccount(InsuranceInfo)
