import { PrimaryButton } from 'components/Button'
import useActions from 'hooks/useTimeline/useActions'
import { useCallback, useMemo } from 'react'
import { useOnboardingContext } from 'context/OnboardingContext/OnboardingProvider'
import JoinModal from 'components/JoinModal'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import {
  PROGRAMS,
  getProgramName,
  isInsurancePatient,
} from 'utils/program.utils'
import { useAlert } from 'context/AlertContext/AlertContextProvider'
import withAccount, { WithAccountProps } from 'hoc/withAccount'
import { CardFormProps } from '.'

const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || ''
)

const JoinPCOSProgram: React.FC<CardFormProps & WithAccountProps> = ({
  action,
  user,
}) => {
  const { setAlertText } = useAlert()
  const { handleCompleteAction } = useActions()
  const {
    selectedPlan,
    selectedProgram,
    setSelectedProgram,
  } = useOnboardingContext()

  const promoCode = useMemo(() => {
    if (!action.properties?.promoCodes) {
      return undefined
    } else if (!selectedPlan) {
      return undefined
    } else if (
      selectedPlan.metadata.type !== PROGRAMS.FULL_PROGRAM &&
      selectedPlan.metadata.type !== PROGRAMS.INSURANCE
    ) {
      return undefined
    }

    const type =
      selectedPlan.metadata.type === 'insurance'
        ? 'insurance'
        : `${selectedPlan.metadata.commitment}-months`

    const foundPromoCode = action.properties.promoCodes.find(
      (p) => p.type === type
    )

    return foundPromoCode?.code
  }, [selectedPlan])

  const program = useMemo(() => {
    if (!user) {
      return undefined
    } else if (isInsurancePatient(user)) {
      return PROGRAMS.INSURANCE
    } else {
      return PROGRAMS.FULL_PROGRAM
    }
  }, [user])

  const handleOpenModal = useCallback(() => setSelectedProgram(program), [
    program,
  ])
  const handleCloseModal = useCallback(() => setSelectedProgram(undefined), [])

  const onSuccess = useCallback(() => {
    handleCompleteAction(action.id)
    setAlertText('We are configuring your dashboard now.', 'Payment Success!')
  }, [])

  return (
    <Elements stripe={stripePromise}>
      <JoinModal
        program={selectedProgram}
        promo={promoCode}
        handleClose={handleCloseModal}
        onSuccess={onSuccess}
        currentProgram={user.stripeCustomer?.product.type}
      />
      <PrimaryButton
        variant="green"
        onClick={handleOpenModal}
        className="spaced-capital-letters fs-2"
      >
        Join {getProgramName(PROGRAMS.FULL_PROGRAM)}
      </PrimaryButton>
    </Elements>
  )
}

export default withAccount(JoinPCOSProgram)
