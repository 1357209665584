import type {
  Expandable,
  Member,
  Message,
  Thread as ThreadResource,
} from '@allara-health/source-health-client'
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
} from 'react'
import { useSourceClient } from 'context/SourceContext/SourceElements'

import { ThreadContext, MessageCreateInputs } from './ThreadContext'

import { threadInitialState, threadReducer } from './reducer'
import useThreads from 'hooks/useThreads/useThreads'
import useMember from 'hooks/useUser/useMember'

export interface ThreadProps {
  /**
   * Identifier of the thread that should be rendered
   */
  id: string

  /**
   * Callback invoked when a message is sent using elements
   */
  onSend?: () => void

  children: React.ReactNode
}

export const Thread: FunctionComponent<ThreadProps> = ({
  id,
  children,
  onSend,
}) => {
  const client = useSourceClient()
  const { member } = useMember()
  const [state, dispatch] = useReducer(threadReducer, threadInitialState)
  const { messages, isLoading, hasMoreMessages } = state
  const { sendMessage } = useThreads()

  const handleSend = async ({
    message,
    threadId,
    attachments,
  }: MessageCreateInputs) => {
    const tempMessage: Message = {
      object: 'message',
      id: `msg_${Math.random().toString(32)}`,
      type: 'text',
      thread: (id as unknown) as Expandable<ThreadResource>,
      text: message,
      sender: member as Member,
      channel_type: 'chat',
      channel: null,
      to: null,
      from: null,
      direction: 'inbound',
      status: 'pending',
      sent_at: new Date().toISOString(),
      // attachments: [],
      attachments:
        attachments?.map((a) => ({
          type: 'file',
          description: a.name,
          url: a.url,
          metadata: {},
          resource: a,
        })) ?? [],
      impersonated_by: null,
      redacted_at: null,
    }

    dispatch({
      type: 'sendMessage',
      message: tempMessage,
    })

    try {
      const newMessage = await sendMessage({
        message: message,
        threadId: threadId,
        attachments: attachments,
      })

      if (newMessage) {
        dispatch({
          type: 'sendMessageSuccess',
          temporaryMessage: tempMessage,
          message: newMessage,
        })
      }

      onSend?.()
    } catch (ex) {
      dispatch({
        type: 'sendMessageFailure',
        temporaryMessage: tempMessage,
      })
    }
  }

  const fetchMoreMessages = useCallback(async () => {
    if (!hasMoreMessages || isLoading) {
      return
    }

    const oldestMessage = messages[0]

    dispatch({
      type: 'loadMoreMessages',
    })

    const moreMessages = await client.communications.messages.list(
      {
        thread: id,
        starting_after: oldestMessage.id,
      },
      {
        expand: [
          'data.sender',
          'data.sender.profile_image',
          'data.attachments.resource',
        ],
      }
    )

    dispatch({
      type: 'loadMoreMessagesSuccess',
      messages: moreMessages.data.reverse(),
      hasMore: moreMessages.has_more,
    })
  }, [id, messages, isLoading, hasMoreMessages])

  const value = useMemo(
    () => ({
      id,
      isLoading,
      messages,
      hasMoreMessages: hasMoreMessages,
      fetchMoreMessages: fetchMoreMessages,
      handleSend,
      dispatch,
    }),
    [
      id,
      messages,
      fetchMoreMessages,
      handleSend,
      hasMoreMessages,
      isLoading,
      dispatch,
    ]
  )

  useEffect(() => {
    dispatch({ type: 'initializeThread', id })

    client.communications.messages
      .list(
        {
          thread: id,
          limit: 15,
        },
        {
          expand: [
            'data.sender',
            'data.sender.profile_image',
            'data.attachments.resource',
          ],
        }
      )
      .then((messages) => {
        dispatch({
          type: 'initializeThreadSuccess',
          messages: messages.data.reverse(),
          hasMore: messages.has_more,
        })
      })
  }, [id])

  return (
    <ThreadContext.Provider value={value}>{children}</ThreadContext.Provider>
  )
}
