import { Automation as AutomationRoute } from 'api/automations/routes'
import { PrimaryButton } from 'components/Button'
import useActions from 'hooks/useTimeline/useActions'
import { useCallback } from 'react'
import axios from 'axios'
import { Formik, FormikHelpers } from 'formik'
import { Col, Form, OverlayTrigger, Row, Tooltip } from 'react-bootstrap'
import TextField from 'components/TextField'
import { CardFormProps } from '.'
import { useStytch, useStytchSession } from '@stytch/react'

interface FormSchema {
  weight: string
}

const GLP1WeightForm: React.FC<CardFormProps> = ({
  action,
  actionDefinition,
}) => {
  const { handleCompleteAction } = useActions()
  const { session } = useStytchSession()
  const stytch = useStytch()

  const initialValues = {
    weight: '',
  }

  const shareWeight = async (data: FormSchema) => {
    if (!session) {
      return null
    }

    const tokens = stytch.session.getTokens()
    const accessToken = tokens ? tokens.session_jwt : undefined
    return axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}${AutomationRoute}/patient/weight`,
        data,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      )
      .then(() => handleCompleteAction(action.id))
      .catch(() => null)
  }

  const handleSubmit = useCallback(
    (values: FormSchema, { setSubmitting }: FormikHelpers<FormSchema>) => {
      shareWeight(values).then(() => setSubmitting(false))
    },
    []
  )

  return (
    <div>
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        {({
          values: { weight },
          errors,
          touched,
          isSubmitting,
          handleChange,
          handleSubmit,
        }) => {
          const submitButtonDisabled = !weight || isSubmitting

          const submitButton = (
            <PrimaryButton
              type="submit"
              variant="green"
              className="spaced-capital-letters fs-2"
              disabled={submitButtonDisabled}
              style={{ pointerEvents: submitButtonDisabled ? 'none' : 'auto' }}
            >
              {actionDefinition.buttonText}
            </PrimaryButton>
          )

          return (
            <Form onSubmit={handleSubmit} noValidate>
              <Row>
                <Col xs={12} className="d-flex align-items-end">
                  <TextField
                    label="Weight*"
                    type="number"
                    maxLength={4}
                    value={weight}
                    onChange={handleChange}
                    controlId="weight"
                    className="flex-grow-1"
                    error={errors.weight && touched.weight ? errors.weight : ''}
                  />
                  <label className="pb-2 pl-2">lbs</label>
                </Col>
              </Row>
              <Row>
                <div className="d-flex justify-content-flex-start mt-2 form-padding">
                  {submitButtonDisabled && !isSubmitting && (
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id="submit-button-tool-tip">
                          Please ensure all fields are completed
                        </Tooltip>
                      }
                    >
                      <span>{submitButton}</span>
                    </OverlayTrigger>
                  )}
                  {(!submitButtonDisabled || isSubmitting) && submitButton}
                </div>
              </Row>
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}

export default GLP1WeightForm
