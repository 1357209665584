import { PrimaryButton } from 'components/Button'
import useActions from 'hooks/useTimeline/useActions'
import { useCallback } from 'react'
import { CardFormProps } from '.'

const ScheduledCompletedLabs: React.FC<CardFormProps> = ({
  action,
  actionDefinition,
}) => {
  const {
    handleCompleteAction,
    completeLabsActions,
    refetchActions,
  } = useActions()

  const onSubmit = useCallback(() => {
    handleCompleteAction(action.id)
    completeLabsActions(action.definition).then(() => {
      if (action.definition === 'completed-labs') {
        setTimeout(() => {
          refetchActions()
        }, 3000)
      }
    })
  }, [action, handleCompleteAction, completeLabsActions])

  return (
    <>
      <PrimaryButton
        variant="green"
        onClick={onSubmit}
        className="spaced-capital-letters fs-2 mt-3"
      >
        {actionDefinition.buttonText}
      </PrimaryButton>
    </>
  )
}

export default ScheduledCompletedLabs
