import { PrimaryButton } from 'components/Button'
import useActions from 'hooks/useTimeline/useActions'
import { useCallback } from 'react'

const HowLongLabsForm: React.FC = () => {
  const { handleRespondHowLongLabs } = useActions()

  const handleClick = useCallback((response: 'more' | 'less') => {
    handleRespondHowLongLabs(response)
  }, [])
  const handleLessThanClick = useCallback(() => handleClick('less'), [])
  const handleMoreThanClick = useCallback(() => handleClick('more'), [])

  return (
    <div className="d-flex justify-content-flex-start">
      <PrimaryButton
        variant="green"
        onClick={handleLessThanClick}
        className="spaced-capital-letters fs-2"
      >
        Less than 2 weeks
      </PrimaryButton>
      <div style={{ width: '15px' }}></div>
      <PrimaryButton
        variant="green"
        onClick={handleMoreThanClick}
        className="spaced-capital-letters fs-2"
      >
        More than 2 weeks
      </PrimaryButton>
    </div>
  )
}

export default HowLongLabsForm
